export const normalizeValue = (value) => {
  return value === null || value === undefined ? "No Record" : value.toString();
};

export const minDate = (programTermStartDate) => {
  const currentDateObj = new Date();
  const programTermStartDateObj = new Date(programTermStartDate);
  if (!programTermStartDate || programTermStartDateObj < currentDateObj) {
    return currentDateObj.toISOString().split("T")[0];
  }
  return programTermStartDate;
};
